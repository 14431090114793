import { ApiResource } from "./api-resource"
import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { OwnershipType, OwnershipTypeSerializer } from "./onwership-type"
import { Country, CountrySerializer } from "./country"
import { Currency, CurrencySerializer } from "./currency"
import { AssociativeArray, JsonObject } from "../_core/types"
import { SelectOption, SelectOptionSerializer } from "./select-option"
import { Language, LanguageSerializer } from "./language"
import { BusinessType, BusinessTypeSerializer } from "./enums/business-type"
import { SellerPermissionCategory, SellerPermissionCategorySerializer } from "@app/_models/seller-permission-category"
import { SellerPermission, SellerPermissionSerializer } from "@app/_models/seller-permission"

export class Enums implements ApiResource {
    id: number
    simpleTrueFalseOptions: SelectOption[]
    currencies: Currency[]
    languages: Language[]
    countries: Country[]
    addressTypes: AssociativeArray
    customerUserPermissions: AssociativeArray
    priceRegularityOptions: AssociativeArray
    priceTypes: AssociativeArray
    productTypeOptions: SelectOption[]
    ownershipTypes: OwnershipType[]
    businessTypes: BusinessType[]
    mccCodes: AssociativeArray
    customerUserRoles: AssociativeArray

    sellerStatusOptions: SelectOption[]
    customerStatusOptions: SelectOption[]
    customerOverallStatusOptions: SelectOption[]
    applicationOverallStatusOptions: SelectOption[]
    customerListOverallStatusOptions: SelectOption[]
    customerContractStatusOptions: SelectOption[]
    customerFinanceStatusOptions: SelectOption[]
    customerMidStatusOptions: SelectOption[]
    customerProductStatusOptions: SelectOption[]

    saleBeenDoneOptions: SelectOption[]
    partnerPortalWidgets: SelectOption[]

    sellerDataAccessOptions: AssociativeArray
    sellerPermissions: SellerPermission[]
    sellerCategorizedPermissions: SellerPermissionCategory[]
    sellerDashboardPermissions: SellerPermission[]
    sellerRoles: AssociativeArray

    addressTypeOptions = []
    genderOptions: SelectOption[]
    titleOptions: SelectOption[]

    estAverageCardPaymentLevels: SelectOption[]
    estAnnualTurnoverLevels: SelectOption[]
    estAnnualTurnoverPercentShareLevels: SelectOption[]
    customerAccountUsageOptions: SelectOption[]
    contractLengthOptions: SelectOption[]
    turnoverPeriodOptions: SelectOption[]
    companyTypeOptions: SelectOption[]
    partnershipTypeOptions: SelectOption[]

    estTransactionsPerYearLevels: SelectOption[]

    personIdTypes: SelectOption[]
    partnerCompanyTypeOptions: SelectOption[]
    partnerCommissionPaymentOptions: SelectOption[]

    errorRequestCategories: SelectOption[]
    errorRequestStatusOptions: SelectOption[]
    errorRequestRequiredSolutionOptions: SelectOption[]
    errorRequestSolverTypesOptions: SelectOption[]
    errorRequestAssignedToOptions: SelectOption[]

    yearOptions: SelectOption[]
    monthOptions: SelectOption[]
    lengthInYearsOptions: SelectOption[]
    lengthInMonthsOptions: SelectOption[]

    permissionsModeOptions: SelectOption[]

    notificationCategoryOptions: SelectOption[]
    notificationTypeOptions: SelectOption[]
    notificationPriorityOptions: SelectOption[]
    notificationStatusOptions: SelectOption[]
    transactionChannelTypeOptions: SelectOption[]
    transactionStatusOptions: SelectOption[]
    convertPciFeeOptions: SelectOption[]

    cardGroups: AssociativeArray

    public getOptionName(property: string, id: number | string, field: string = "name"): string {
        if (this[property] === undefined) {
            return ""
        }
        const foundItem = this[property].find(item => item.id === id)
        return foundItem ? foundItem[field] : "N/A"
    }

    public getMccCodeDescription(id: string): string {
        let foundCode = null
        for (let i = 0; i <= +this.mccCodes.length - 1; i++) {
            if (this.mccCodes[i]?.id === id) {
                foundCode = this.mccCodes[i]
            }
        }
        return foundCode ? foundCode.description : "n/a"
    }
}

@Injectable({
    providedIn: "root",
})
export class EnumsSerializer implements ApiResourceSerializer {
    constructor(
        private currencySerializer: CurrencySerializer,
        private countrySerializer: CountrySerializer,
        private ownershipTypeSerializer: OwnershipTypeSerializer,
        private selectOptionSerializer: SelectOptionSerializer,
        private languageSerializer: LanguageSerializer,
        private businessTypeSerializer: BusinessTypeSerializer,
        private sellerPermissionCategorySerializer: SellerPermissionCategorySerializer,
        private sellerPermissionSerializer: SellerPermissionSerializer,
    ) {}

    fromJson(json: JsonObject): Enums {
        if (json === null) {
            return null
        }

        const enums = new Enums()
        enums.addressTypes = json.address_types
        enums.customerUserPermissions = json.customer_user_permissions
        enums.priceRegularityOptions = json.price_regularity_options
        enums.priceTypes = json.price_types
        enums.transactionStatusOptions =
            json.transaction_status_options !== undefined
                ? json.transaction_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.productTypeOptions =
            json.product_type_options !== undefined
                ? json.product_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.mccCodes = json.mcc_codes
        enums.customerUserRoles = json.customer_user_roles

        enums.sellerDataAccessOptions = json.seller_data_access_options

        enums.sellerPermissions =
            json.seller_permissions !== undefined
                ? json.seller_permissions.map(item => this.sellerPermissionSerializer.fromJson(item))
                : []

        enums.sellerCategorizedPermissions =
            json.seller_categorized_permissions !== undefined
                ? json.seller_categorized_permissions.map(item =>
                      this.sellerPermissionCategorySerializer.fromJson(item),
                  )
                : []

        enums.sellerDashboardPermissions =
            json.seller_dashboard_permissions !== undefined
                ? json.seller_dashboard_permissions.map(item => this.sellerPermissionSerializer.fromJson(item))
                : []

        enums.sellerRoles = json.seller_roles

        enums.simpleTrueFalseOptions =
            json.simple_true_false_options !== undefined
                ? json.simple_true_false_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.currencies =
            json.currencies !== undefined ? json.currencies.map(item => this.currencySerializer.fromJson(item)) : []

        enums.languages =
            json.languages !== undefined ? json.languages.map(item => this.languageSerializer.fromJson(item)) : []

        enums.countries =
            json.countries !== undefined
                ? (json.countries.map(item => this.countrySerializer.fromJson(item)) as Country[])
                : []

        enums.ownershipTypes =
            json.ownership_types !== undefined
                ? json.ownership_types.map(item => this.ownershipTypeSerializer.fromJson(item))
                : []
        enums.businessTypes =
            json.business_types !== undefined
                ? json.business_types.map(item => this.businessTypeSerializer.fromJson(item))
                : []
        enums.addressTypeOptions =
            json.address_types !== undefined
                ? json.address_types.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.genderOptions =
            json.gender_type_options !== undefined
                ? json.gender_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.titleOptions =
            json.title_type_options !== undefined
                ? json.title_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.sellerStatusOptions =
            json.seller_status_options !== undefined
                ? json.seller_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerStatusOptions =
            json.customer_status_options !== undefined
                ? json.customer_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerOverallStatusOptions =
            json.customer_overall_status_options !== undefined
                ? json.customer_overall_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.applicationOverallStatusOptions =
            json.application_overall_status_options !== undefined
                ? json.application_overall_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerListOverallStatusOptions =
            json.customer_list_overall_status_options !== undefined
                ? json.customer_list_overall_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerContractStatusOptions =
            json.customer_contract_status_options !== undefined
                ? json.customer_contract_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerFinanceStatusOptions =
            json.customer_finance_status_options !== undefined
                ? json.customer_finance_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerMidStatusOptions =
            json.customer_mid_status_options !== undefined
                ? json.customer_mid_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerProductStatusOptions =
            json.customer_product_status_options !== undefined
                ? json.customer_product_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.partnerPortalWidgets =
            json.partner_portal_widgets !== undefined
                ? json.partner_portal_widgets.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.saleBeenDoneOptions =
            json.sale_been_done_options !== undefined
                ? json.sale_been_done_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.estAverageCardPaymentLevels =
            json.est_average_card_payment_levels !== undefined
                ? json.est_average_card_payment_levels.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.estAnnualTurnoverLevels =
            json.est_annual_turnover_levels !== undefined
                ? json.est_annual_turnover_levels.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.estAnnualTurnoverPercentShareLevels =
            json.est_annual_turnover_percent_share_levels !== undefined
                ? json.est_annual_turnover_percent_share_levels.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.customerAccountUsageOptions =
            json.customer_account_usage_options !== undefined
                ? json.customer_account_usage_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.contractLengthOptions =
            json.contract_length_options !== undefined
                ? json.contract_length_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.turnoverPeriodOptions =
            json.turnover_period_options !== undefined
                ? json.turnover_period_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.companyTypeOptions =
            json.company_type_options !== undefined
                ? json.company_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.partnershipTypeOptions =
            json.partnership_type_options !== undefined
                ? json.partnership_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.estTransactionsPerYearLevels =
            json.est_transactions_per_year_levels !== undefined
                ? json.est_transactions_per_year_levels.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.personIdTypes =
            json.person_id_types !== undefined
                ? json.person_id_types.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.partnerCompanyTypeOptions =
            json.partner_company_type_options !== undefined
                ? json.partner_company_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.partnerCommissionPaymentOptions =
            json.partner_commission_payment_options !== undefined
                ? json.partner_commission_payment_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.errorRequestCategories =
            json.error_request_categories !== undefined
                ? json.error_request_categories.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.errorRequestStatusOptions =
            json.error_request_status_options !== undefined
                ? json.error_request_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.errorRequestRequiredSolutionOptions =
            json.error_request_required_solution_options !== undefined
                ? json.error_request_required_solution_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.errorRequestSolverTypesOptions =
            json.error_request_solver_types_options !== undefined
                ? json.error_request_solver_types_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.errorRequestAssignedToOptions =
            json.error_request_assigned_to_options !== undefined
                ? json.error_request_assigned_to_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.yearOptions =
            json.year_options !== undefined
                ? json.year_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.monthOptions =
            json.month_options !== undefined
                ? json.month_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.lengthInYearsOptions =
            json.length_in_years_options !== undefined
                ? json.length_in_years_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.lengthInMonthsOptions =
            json.length_in_months_options !== undefined
                ? json.length_in_months_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.permissionsModeOptions =
            json.permissions_mode_options !== undefined
                ? json.permissions_mode_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.notificationCategoryOptions =
            json.notification_category_options !== undefined
                ? json.notification_category_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.notificationTypeOptions =
            json.notification_type_options !== undefined
                ? json.notification_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.notificationPriorityOptions =
            json.notification_priority_options !== undefined
                ? json.notification_priority_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.notificationStatusOptions =
            json.notification_status_options !== undefined
                ? json.notification_status_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.transactionChannelTypeOptions =
            json.transaction_channel_type_options !== undefined
                ? json.transaction_channel_type_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []

        enums.convertPciFeeOptions =
            json.convert_pci_fee_options !== undefined
                ? json.convert_pci_fee_options.map(item => this.selectOptionSerializer.fromJson(item))
                : []
        enums.cardGroups = json.card_groups

        return enums
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(enums: Enums): any {
        return {
            customer_user_permissions: enums.customerUserPermissions,
            price_regularity_options: enums.priceRegularityOptions,
            price_types: enums.priceTypes,
            product_type_options:
                enums.productTypeOptions !== undefined
                    ? enums.productTypeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            mcc_codes: enums.mccCodes,
            customer_user_roles: enums.customerUserRoles,

            seller_data_access_options: enums.sellerDataAccessOptions,
            seller_permissions: (enums.sellerPermissions =
                enums.sellerPermissions !== undefined
                    ? enums.sellerPermissions.map(item => this.sellerPermissionSerializer.toJson(item))
                    : []),

            seller_categorized_permissions: (enums.sellerCategorizedPermissions =
                enums.sellerCategorizedPermissions !== undefined
                    ? enums.sellerCategorizedPermissions.map(item =>
                          this.sellerPermissionCategorySerializer.toJson(item),
                      )
                    : []),

            seller_dashboard_permissions: (enums.sellerDashboardPermissions =
                enums.sellerDashboardPermissions !== undefined
                    ? enums.sellerDashboardPermissions.map(item => this.sellerPermissionSerializer.toJson(item))
                    : []),
            seller_roles: enums.sellerRoles,

            currencies:
                enums.currencies !== undefined
                    ? enums.currencies.map(item => this.currencySerializer.toJson(item))
                    : [],

            simple_true_false_options:
                enums.simpleTrueFalseOptions !== undefined
                    ? enums.simpleTrueFalseOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],

            languages:
                enums.languages !== undefined ? enums.languages.map(item => this.languageSerializer.toJson(item)) : [],
            countries:
                enums.countries !== undefined ? enums.countries.map(item => this.countrySerializer.toJson(item)) : [],
            ownership_types:
                enums.ownershipTypes !== undefined
                    ? enums.ownershipTypes.map(item => this.ownershipTypeSerializer.toJson(item))
                    : [],
            business_types:
                enums.businessTypes !== undefined
                    ? enums.businessTypes.map(item => this.businessTypeSerializer.toJson(item))
                    : [],
            address_types:
                enums.addressTypeOptions !== undefined
                    ? enums.addressTypeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            gender_type_options:
                enums.genderOptions !== undefined
                    ? enums.genderOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            title_type_options:
                enums.titleOptions !== undefined
                    ? enums.titleOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            seller_status_options:
                enums.sellerStatusOptions !== undefined
                    ? enums.sellerStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_status_options:
                enums.customerStatusOptions !== undefined
                    ? enums.customerStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_overall_status_options:
                enums.customerOverallStatusOptions !== undefined
                    ? enums.customerOverallStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            application_overall_status_options:
                enums.applicationOverallStatusOptions !== undefined
                    ? enums.applicationOverallStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_list_overall_status_options:
                enums.customerListOverallStatusOptions !== undefined
                    ? enums.customerListOverallStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_contract_status_options:
                enums.customerContractStatusOptions !== undefined
                    ? enums.customerContractStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_finance_status_options:
                enums.customerFinanceStatusOptions !== undefined
                    ? enums.customerFinanceStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_mid_status_options:
                enums.customerMidStatusOptions !== undefined
                    ? enums.customerMidStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_product_status_options:
                enums.customerProductStatusOptions !== undefined
                    ? enums.customerProductStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            partner_portal_widgets:
                enums.partnerPortalWidgets !== undefined
                    ? enums.partnerPortalWidgets.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            sale_been_done_options:
                enums.saleBeenDoneOptions !== undefined
                    ? enums.saleBeenDoneOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],

            est_average_card_payment_levels:
                enums.estAverageCardPaymentLevels !== undefined
                    ? enums.estAverageCardPaymentLevels.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            est_annual_turnover_levels:
                enums.estAnnualTurnoverLevels !== undefined
                    ? enums.estAnnualTurnoverLevels.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            est_annual_turnover_percent_share_levels:
                enums.estAnnualTurnoverPercentShareLevels !== undefined
                    ? enums.estAnnualTurnoverPercentShareLevels.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            customer_account_usage_options:
                enums.customerAccountUsageOptions !== undefined
                    ? enums.customerAccountUsageOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            contract_length_options:
                enums.contractLengthOptions !== undefined
                    ? enums.contractLengthOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            turnover_period_options:
                enums.turnoverPeriodOptions !== undefined
                    ? enums.turnoverPeriodOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            company_type_options:
                enums.companyTypeOptions !== undefined
                    ? enums.companyTypeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            partnership_type_options:
                enums.partnershipTypeOptions !== undefined
                    ? enums.partnershipTypeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],

            est_transactions_per_year_levels:
                enums.estTransactionsPerYearLevels !== undefined
                    ? enums.estTransactionsPerYearLevels.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            person_id_types:
                enums.personIdTypes !== undefined
                    ? enums.personIdTypes.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            partner_company_type_options:
                enums.partnerCompanyTypeOptions !== undefined
                    ? enums.partnerCompanyTypeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            partner_commission_payment_options:
                enums.partnerCommissionPaymentOptions !== undefined
                    ? enums.partnerCommissionPaymentOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            error_request_categories:
                enums.errorRequestCategories !== undefined
                    ? enums.errorRequestCategories.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            error_request_status_options:
                enums.errorRequestStatusOptions !== undefined
                    ? enums.errorRequestStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            error_request_required_solution_options:
                enums.errorRequestRequiredSolutionOptions !== undefined
                    ? enums.errorRequestRequiredSolutionOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            error_request_solver_types_options:
                enums.errorRequestSolverTypesOptions !== undefined
                    ? enums.errorRequestSolverTypesOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            error_request_assigned_to_options:
                enums.errorRequestAssignedToOptions !== undefined
                    ? enums.errorRequestAssignedToOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            year_options:
                enums.yearOptions !== undefined
                    ? enums.yearOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            month_options:
                enums.monthOptions !== undefined
                    ? enums.monthOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            length_in_years_options:
                enums.lengthInYearsOptions !== undefined
                    ? enums.lengthInYearsOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            length_in_months_options:
                enums.lengthInMonthsOptions !== undefined
                    ? enums.lengthInMonthsOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            permissions_mode_options:
                enums.permissionsModeOptions !== undefined
                    ? enums.permissionsModeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            transaction_channel_type_options:
                enums.transactionChannelTypeOptions !== undefined
                    ? enums.transactionChannelTypeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            convert_pci_fee_options:
                enums.convertPciFeeOptions !== undefined
                    ? enums.convertPciFeeOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            notification_priority_options:
                enums.notificationPriorityOptions !== undefined
                    ? enums.notificationPriorityOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            transaction_status_options:
                enums.transactionStatusOptions !== undefined
                    ? enums.transactionStatusOptions.map(item => this.selectOptionSerializer.toJson(item))
                    : [],
            card_groups: enums.cardGroups,
        }
    }

    convertAssocArrayToSelectOptions(items: AssociativeArray): { id: number; name: string }[] {
        const options = []
        for (const key of Object.keys(items)) {
            options.push({
                id: key,
                name: items[key],
            })
        }
        return options
    }
}
