import { CustomerRegistrationAuthGuard } from "./_guards/customer-registration-auth.guard"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "./_guards/auth.guard"
import { PermissionGuard } from "./_guards/permission.guard"
import { AnimateGuard } from "@app/_guards/animate.guard"

const routes: Routes = [
    {
        path: "",
        loadChildren: () => import("./_modules/homepage.module").then(x => x.HomepageModule),
        canActivate: [AuthGuard, PermissionGuard, AnimateGuard],
        pathMatch: "full",
    },
    {
        path: "login",
        loadChildren: () => import("./_modules/login.module").then(x => x.LoginModule),
    },

    {
        path: "customers",
        loadChildren: () => import("./_modules/customers.module").then(x => x.CustomersModule),
        canActivate: [AuthGuard, PermissionGuard, AnimateGuard],
    },

    {
        path: "customer",
        loadChildren: () => import("./_modules/customer.module").then(x => x.CustomerModule),
        canActivate: [AuthGuard, AnimateGuard],
    },

    {
        path: "transactions",
        loadChildren: () => import("./_modules/transactions.module").then(x => x.TransactionsModule),
        canActivate: [AuthGuard, PermissionGuard, AnimateGuard],
    },

    {
        path: "commissions",
        loadChildren: () => import("./_modules/commissions.module").then(x => x.CommissionsModule),
        canActivate: [AuthGuard, PermissionGuard, AnimateGuard],
    },

    {
        path: "messages",
        loadChildren: () => import("./_modules/messages.module").then(x => x.MessagesModule),
        canActivate: [AuthGuard, PermissionGuard, AnimateGuard],
    },

    {
        path: "settings",
        loadChildren: () => import("./_modules/settings.module").then(x => x.SettingsModule),
        canActivate: [AuthGuard, AnimateGuard],
    },

    {
        path: "settings/user-groups",
        loadChildren: () => import("./_modules/user-groups.module").then(x => x.UserGroupsModule),
        canActivate: [AuthGuard],
    },

    {
        path: "settings/alerts",
        loadChildren: () => import("./_modules/alerts.module").then(x => x.AlertModule),
        canActivate: [AuthGuard],
    },

    {
        path: "settings/notifications",
        loadChildren: () => import("./_modules/notifications.module").then(x => x.NotificationsModule),
        canActivate: [AuthGuard],
    },

    {
        path: "settings/login-history",
        loadChildren: () => import("./_modules/login-history.module").then(x => x.LoginHistoryModule),
        canActivate: [AuthGuard, PermissionGuard],
    },

    {
        path: "customer-registration",
        loadChildren: () => import("./_modules/customer-registration.module").then(x => x.CustomerRegistrationModule),
        canActivate: [CustomerRegistrationAuthGuard, PermissionGuard],
    },

    {
        path: "customer-quote",
        loadChildren: () => import("./_modules/customer-quote.module").then(x => x.CustomerQuoteModule),
        canActivate: [CustomerRegistrationAuthGuard, PermissionGuard],
    },

    {
        path: "users/account-activation",
        loadChildren: () => import("./_modules/account-activation.module").then(x => x.AccountActivationModule),
    },

    {
        path: "accounts",
        loadChildren: () => import("./_modules/accounts.module").then(x => x.AccountsModule),
        canActivate: [AuthGuard, PermissionGuard],
    },

    {
        path: "card-rates",
        loadChildren: () => import("./_modules/card-rates.module").then(x => x.CardRatesModule),
        canActivate: [AuthGuard],
    },

    {
        path: "pin-reset",
        loadChildren: () => import("./_modules/pin-reset.module").then(x => x.PinResetModule),
    },

    {
        path: "commercials",
        loadChildren: () => import("./_modules/commercials.module").then(x => x.CommercialsModule),
        canActivate: [AuthGuard],
    },

    {
        path: "reports",
        loadChildren: () => import("./_modules/reports.module").then(x => x.ReportsModule),
        canActivate: [AuthGuard],
    },

    // Otherwise redirect to home

    {
        path: "**",
        redirectTo: "/login",
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            { enableTracing: false }, // <-- debugging purposes only - relativeLinkResolution: "legacy"
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
