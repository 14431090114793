import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { AuthenticationService } from "@app/_services/authentication.service"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import VerofyHelper from "../_helpers/verofy.helper"
import { CustomerRegistrationService } from "../_services/customer-registration.service"
import { CustomerUtilitiesService } from "../_services/customer-utilities.service"
import { Router } from "@angular/router"

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
        private customerRegistrationService: CustomerRegistrationService,
        private customerUtilitiesService: CustomerUtilitiesService,
        private router: Router,
    ) {}

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.handleAccess(request, next)
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleAccess(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check whether the interceptor should be used.
        const { headers } = request
        if (headers.has("No-API-Auth") && headers.get("No-API-Auth") === "yes") {
            const _headers = headers.delete("No-API-Auth")
            return next.handle(request.clone({ headers: _headers }))
        }

        // Prepare additional headers.
        const newHeaders: { [name: string]: string | string[] } = {}
        if (headers.has("Content-Type") === false) {
            newHeaders["Content-Type"] = "application/json"
        }
        if (headers.has("Accept") === false) {
            newHeaders["Accept"] = "application/json"
        }

        // Add master request id for api communication tracking.
        newHeaders["V2-Master-Request-ID"] = VerofyHelper.generateMasterRequestId()

        // Add authorization bearer token header.
        const authToken = this.authService.getAccessToken()
        if (authToken) {
            newHeaders["Authorization"] = "Bearer " + authToken
        }

        // Add customer registration hash header if needed.
        const isCustomerRegistration = this.router.routerState.snapshot.url.startsWith("/customer-registration")
        const isCustomerQuote = this.router.routerState.snapshot.url.startsWith("/customer-quote")

        if (isCustomerRegistration || isCustomerQuote) {
            const customerRegistrationHash = this.customerRegistrationService.getRecordHash(
                isCustomerRegistration ? "customer-registration" : "customer-quote",
            )
            if (customerRegistrationHash !== null && customerRegistrationHash !== undefined) {
                newHeaders[CustomerRegistrationService.AUTH_HEADER_NAME] = customerRegistrationHash
            }
        }

        // Add customer utility quote hash if needed.
        const customerUtilityQuoteHash = this.customerUtilitiesService.getQuoteHash()
        if (customerUtilityQuoteHash !== null && customerUtilityQuoteHash !== undefined) {
            newHeaders[CustomerUtilitiesService.AUTH_HEADER_NAME] = customerUtilityQuoteHash
        }

        // Add V2-Device-Unique-ID header if needed.
        const deviceUniqueId = this.authService.getDeviceUniqueId()
        if (deviceUniqueId !== undefined && deviceUniqueId !== null) {
            newHeaders["V2-Device-Unique-ID"] = deviceUniqueId
        }

        // Add V2-Device-Platform header.
        newHeaders["V2-Device-Platform"] = "web_portal"

        const modifiedRequest = request.clone({
            headers: this.addExtraHeaders(request.headers, newHeaders),
        })
        return next.handle(modifiedRequest)
    }

    /**
     * Adds additional HTTP headers to the request
     */
    private addExtraHeaders(headers: HttpHeaders, newHeaders: { [name: string]: string | string[] }): HttpHeaders {
        let modifiedHeaders = headers

        // check headers that should be set by default
        if (modifiedHeaders.has("Content-Type") && headers.get("Content-Type") === "undefined") {
            modifiedHeaders = modifiedHeaders.delete("Content-Type")
        }
        if (modifiedHeaders.has("Accept") && headers.get("Accept") === "undefined") {
            modifiedHeaders = modifiedHeaders.delete("Accept")
        }

        // loop through the new headers that should be add
        for (const key in newHeaders) {
            if (Object.prototype.hasOwnProperty.call(newHeaders, key)) {
                modifiedHeaders = modifiedHeaders.append(key, newHeaders[key])
            }
        }

        return modifiedHeaders
    }
}
