import { HttpHeaders, HttpParams } from "@angular/common/http"
import { AssociativeArray } from "../../_core/types"

export class ApiBaseService {
    protected toQueryString(paramsObject: []): string {
        return Object.keys(paramsObject)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
            .join("&")
    }

    protected prepareHttpParams(queryOptions: AssociativeArray<string>): HttpParams {
        let params = new HttpParams()
        for (const key in queryOptions) {
            if (Object.prototype.hasOwnProperty.call(queryOptions, key)) {
                params = params.append(key, queryOptions[key])
            }
        }
        return params
    }

    protected prepareHttpHeaders(customHeaders: AssociativeArray<string>): HttpHeaders {
        let headers = new HttpHeaders()
        for (const key in customHeaders) {
            if (Object.prototype.hasOwnProperty.call(customHeaders, key)) {
                headers = headers.append(key, customHeaders[key])
            }
        }
        return headers
    }
}
