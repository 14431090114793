import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"

import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"

import * as Sentry from "@sentry/angular-ivy"
import { BrowserTracing } from "@sentry/browser"

if (environment.production) {
    enableProdMode()
}

if (environment.disableConsoleLog) {
    window.console.log = () => {
        // Disable console logging for the production
    }
}

if (environment.enableSentry) {
    Sentry.init({
        dsn: "https://c1eb0b62ce85442ca427dd8c8180f2e8@o504497.ingest.sentry.io/5839139",
        environment: environment.sentryEnvironment,
        debug: false /** environment.sentryEnvironment !== 'production' ? true : false, */,
        attachStacktrace: true,
        integrations: [
            new BrowserTracing({
                tracingOrigins: environment.sentryTracingOrigins,
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.tracesSampleRate,
    })
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err))
