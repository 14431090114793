import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "@appCore/api-resource.serializer"
import { JsonObject } from "@appCore/types"

export class VerofyLoyalty {
    id: number
    hasLoyaltyCashback: boolean
    loyaltyTerminalsCount: number
    topUpMonthly: number
    numberOfMonth: number
}

@Injectable({
    providedIn: "root",
})
export class VerofyLoyaltySerializer implements ApiResourceSerializer {
    constructor() {}

    fromJson(json: JsonObject): VerofyLoyalty {
        const record = new VerofyLoyalty()
        record.id = 0
        record.hasLoyaltyCashback = json.has_loyalty_cashback
        record.topUpMonthly = json.top_up_monthly ? json.top_up_monthly : null
        record.numberOfMonth = json.number_of_month ? json.number_of_month : 3
        record.loyaltyTerminalsCount = json.loyalty_terminals_count ? json.loyalty_terminals_count : 1
        return record
    }

    toJson(record: VerofyLoyalty): JsonObject {
        if (record === null) {
            return {}
        }
        return {
            id: record.id, // Including ID if necessary
            has_loyalty_cashback: record.hasLoyaltyCashback,
            top_up_monthly: record.topUpMonthly,
            number_of_month: record.numberOfMonth,
            loyalty_terminals_count: record.loyaltyTerminalsCount,
        }
    }
}
