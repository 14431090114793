import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"
import { CRContactAddress, CRContactAddressSerializer } from "./cr-contact-address"

export class CRPartnerRecord {
    id: number = 0
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    email: string | null
    share: number | null
    fieldId?: number
    address: CRContactAddress
    previousAddress?: CRContactAddress
}

@Injectable({
    providedIn: "root",
})
export class CRPartnerRecordSerializer implements ApiResourceSerializer {
    constructor(private cRContactAddressSerializer: CRContactAddressSerializer) {}

    fromJson(json: JsonObject): CRPartnerRecord {
        const record = new CRPartnerRecord()
        record.id = json.id
        record.firstName = json.first_name
        record.lastName = json.last_name
        record.phoneNumber = json.phone_number
        record.email = json.email
        record.share = json.share
        record.fieldId = json.field_id
        record.address = json.address ? this.cRContactAddressSerializer.fromJson(json.address) : null
        record.previousAddress = json.previous_address
            ? this.cRContactAddressSerializer.fromJson(json.previous_address)
            : null
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CRPartnerRecord): any {
        return {
            id: record.id,
            first_name: record.firstName,
            last_name: record.lastName,
            phone_number: record.phoneNumber,
            email: record.email,
            share: record.share,
            field_id: record.fieldId,
            address: record.address ? this.cRContactAddressSerializer.toJson(record.address) : null,
            previous_address: record.previousAddress
                ? this.cRContactAddressSerializer.toJson(record.previousAddress)
                : null,
        }
    }
}
