import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"

export class StandardFee {
    id: number
    name: string
    description: string
    isRequired: boolean
    isVatable: boolean
    chargedPer: number
    chargedPerLabel: string
    chargedWithin: number
    chargedWithinLabel: string
    defaultValue: number
    minValue: number
    maxValue: number
}

@Injectable({
    providedIn: "root",
})
export class StandardFeeSerializer {
    fromJson(json: JsonObject): StandardFee {
        const fee = new StandardFee()

        fee.id = json.id
        fee.name = json.name
        fee.description = json.description
        fee.isRequired = json.is_required
        fee.isVatable = json.is_vatable
        fee.chargedPer = json.charged_per
        fee.chargedPerLabel = json.charged_per_label
        fee.chargedWithin = json.charged_within
        fee.chargedWithinLabel = json.charged_within_label
        fee.defaultValue = json.default_value
        fee.minValue = json.min_value
        fee.maxValue = json.max_value

        return fee
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(fee: CustomerStandardFee): any {
        return {}
    }
}
