import { Component } from "@angular/core"

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "svg-question-mark",
    template: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
        <defs>
            <style>
                .cls-3 {
                    fill: none;
                    stroke: #000;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 0.71px;
                }

                .cls-4 {
                    font-family: Roboto-Regular, Roboto;
                    font-size: 14.22px;
                }
            </style>
        </defs>
        <circle class="cls-3" cx="15" cy="15" r="14.19" />
        <text class="cls-4" transform="translate(11.51 20.06)"><tspan x="0" y="0">?</tspan></text>
    </svg>`,
})
export class QuestionMarkComponent {}
