import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { JsonObject } from "../_core/types"

export class SelectOption {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any
    name: string
}

@Injectable({
    providedIn: "root",
})
export class SelectOptionSerializer implements ApiResourceSerializer {
    fromJson(json: JsonObject): SelectOption {
        const type = new SelectOption()
        type.id = json.id
        type.name = json.name

        return type
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(type: SelectOption): any {
        return {
            id: type.id,
            name: type.name,
        }
    }
}
