import { Injectable } from "@angular/core"
import { JsonObject } from "../_core/types"
import { CustomerContract, CustomerContractSerializer } from "@appModels/customer-contract"
import { StandardFee, StandardFeeSerializer } from "@appModels/standard-fee"
import { SystemFile, SystemFileSerializer } from "@appModels/file"
import { StandardFeeEnum } from "@app/enums/customerRegistration/StandardFeeEnum"

export class CustomerStandardFee {
    id: number
    name: string
    description: string
    value: number
    currentValue: number
    isVatable: boolean
    isRequired: boolean
    chargedPer: number
    chargedPerLabel: string
    chargedWithin: number
    chargedWithinLabel: string
    defaultValue: number
    defaultQuantity: number | null = null
    minValue: number
    maxValue: number
    productImage?: SystemFile
    definedValues: number[] | null
    // For customer registration logic
    isActive: boolean = false
    contract: CustomerContract
    standardFee: StandardFee
    code?: StandardFeeEnum = StandardFeeEnum.UNKNOWN
}

@Injectable({
    providedIn: "root",
})
export class CustomerStandardFeeSerializer {
    constructor(
        private customerContractSerializer: CustomerContractSerializer,
        private standardFeeSerializer: StandardFeeSerializer,
        private systemFileSerializer: SystemFileSerializer,
    ) {}

    fromJson(json: JsonObject): CustomerStandardFee {
        const record = new CustomerStandardFee()
        record.id = json.id
        record.name = json.name
        record.description = json.description
        record.value = json.value
        record.currentValue = json.current_value
        record.isVatable = json.is_vatable
        record.isRequired = json.is_required
        record.chargedPer = json.charged_per
        record.productImage =
            json.product_image && json.product_image !== ""
                ? this.systemFileSerializer.fromJson(json.product_image)
                : null
        record.chargedPerLabel = json.charged_per_label
        record.chargedWithin = json.charged_within
        record.chargedWithinLabel = json.charged_within_label
        record.defaultValue = json.default_value
        record.defaultQuantity = json.default_quantity
        record.minValue = json.min_value
        record.maxValue = json.max_value
        record.definedValues = json.defined_values
        record.isActive = json.is_active ?? false
        record.code = json.code ? this.getCode(json.code) : StandardFeeEnum.UNKNOWN
        record.contract =
            json.contract !== undefined && json.contract !== null
                ? this.customerContractSerializer.fromJson(json.contract)
                : null
        record.standardFee =
            json.standard_fee !== undefined && json.standard_fee !== null
                ? this.standardFeeSerializer.fromJson(json.standard_fee)
                : null
        return record
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(record: CustomerStandardFee): any {
        return {
            id: record.id,
            name: record.name,
            description: record.description,
            value: record.value,
            current_value: record.currentValue,
            is_vatable: record.isVatable,
            is_required: record.isRequired,
            charged_per: record.chargedPer,
            charged_per_label: record.chargedPerLabel,
            charged_within: record.chargedWithin,
            charged_within_label: record.chargedWithinLabel,
            default_value: record.defaultValue,
            default_quantity: record.defaultQuantity,
            min_value: record.minValue,
            max_value: record.maxValue,
            defined_values: record.definedValues,
            is_active: record.isActive ?? false,
        }
    }

    private getCode(code: string): StandardFeeEnum {
        switch (code) {
            case StandardFeeEnum.PCI_PLUS_VT_PB:
                return StandardFeeEnum.PCI_PLUS_VT_PB
            case StandardFeeEnum.FASTER_PAYMENT:
                return StandardFeeEnum.FASTER_PAYMENT
            case StandardFeeEnum.PCI_PLUS:
                return StandardFeeEnum.PCI_PLUS
            default:
                return StandardFeeEnum.UNKNOWN
        }
    }
}
