import { Injectable } from "@angular/core"
import { ApiResourceSerializer } from "../_core/api-resource.serializer"
import { Product, ProductSerializer } from "./product"
import { CustomerUserSerializer } from "./customer-user"
import { CustomerProductPriceSerializer } from "./customer-product-price"
import { CustomerLocation, CustomerLocationSerializer } from "./customer-location"
import { JsonObject } from "../_core/types"
import { CustomerContract, CustomerContractSerializer } from "@app/_models/customer-contract"

export class CustomerProduct {
    id = 0
    createdAt: Date
    updatedAt: Date
    qty: number
    contractLength = null
    priceRegularity: number
    isMotoActive: boolean
    product: Product
    customerLocations: CustomerLocation[]
    customerContract: CustomerContract
    // TODO
    /* eslint-disable @typescript-eslint/no-explicit-any */
    customerUser: any
    cardRates: any
    customerFinanceContract: any
    prices: any
    /* eslint-enable @typescript-eslint/no-explicit-any */

    isIntegrated: boolean
    integratedProducts: CustomerProduct[]
    parentCustomerProduct: CustomerProduct
    status: number
    totalPrice = 0
    serialNumber: string

    calculatePrices(): void {
        const self = this

        let totalPrice = 0
        this.product.pricing.forEach(productPrice => {
            totalPrice += productPrice.priceValue
        })
        this.totalPrice = this.qty * totalPrice
        this.integratedProducts.forEach(intProduct => {
            intProduct.calculatePrices()
            self.totalPrice += intProduct.totalPrice
        })
    }

    calculateFinalPrices(): void {
        const self = this

        let totalPrice = 0
        this.prices.forEach(merProductPrice => {
            totalPrice += merProductPrice.priceBuy
        })
        this.totalPrice = this.qty * totalPrice
        this.integratedProducts.forEach(intProduct => {
            intProduct.calculateFinalPrices()
            self.totalPrice += intProduct.totalPrice
        })
    }
}

@Injectable({
    providedIn: "root",
})
export class CustomerProductSerializer implements ApiResourceSerializer {
    constructor(
        private productSerializer: ProductSerializer,
        private customerLocationSerializer: CustomerLocationSerializer,
        private customerContractSerializer: CustomerContractSerializer,
        private customerUserSerializer: CustomerUserSerializer,
        private customerProductPriceSerializer: CustomerProductPriceSerializer,
    ) {}

    fromJson(json: JsonObject): CustomerProduct {
        const merProduct = new CustomerProduct()
        merProduct.id = json.id
        merProduct.createdAt = new Date(json.created_at)
        merProduct.updatedAt = new Date(json.updated_at)

        merProduct.qty = 1

        merProduct.status = json.status
        merProduct.priceRegularity = json.price_regularity
        merProduct.isMotoActive = json.is_moto_active
        merProduct.contractLength = json.contract_length
        merProduct.product = json.product !== undefined ? this.productSerializer.fromJson(json.product) : null
        merProduct.customerLocations =
            json.customer_locations !== undefined && json.customer_locations !== null
                ? json.customer_locations.map(item => this.customerLocationSerializer.fromJson(item))
                : []
        merProduct.customerContract =
            json.contract !== undefined && json.contract !== null
                ? this.customerContractSerializer.fromJson(json.contract)
                : null
        merProduct.customerUser =
            json.customer_user !== undefined ? this.customerUserSerializer.fromJson(json.customer_user) : null
        merProduct.cardRates = json.card_rates
        merProduct.isIntegrated = json.is_integrated
        merProduct.integratedProducts =
            json.integrated_products !== undefined
                ? json.integrated_products.map(intProduct => this.fromJson(intProduct))
                : []
        merProduct.parentCustomerProduct =
            json.parent_customer_product !== undefined ? this.fromJson(json.parent_customer_product) : null
        merProduct.customerFinanceContract = json.customer_finance_contract

        merProduct.prices =
            json.prices !== null && json.prices !== undefined
                ? json.prices.map(item => this.customerProductPriceSerializer.fromJson(item))
                : null
        merProduct.serialNumber = json.serial_number

        return merProduct
    }

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJson(merProduct: CustomerProduct): any {
        return {
            price_regularity: merProduct.priceRegularity,
            is_moto_active: merProduct.isMotoActive,
            contract_length: merProduct.contractLength,
            qty: merProduct.qty,
            product: this.productSerializer.toJson(merProduct.product),
            integrated_products: merProduct.integratedProducts
                ? merProduct.integratedProducts.map(intProduct => this.toJson(intProduct))
                : [],
            prices: merProduct.prices
                ? merProduct.prices.map(item => this.customerProductPriceSerializer.toJson(item))
                : null,
        }
    }
}
